<template>
  <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.83002 10.023C0.780017 10.023 1.82001 8.95694 1.82001 8.95694L4.5 6.012C4.76 5.726 5.26 5.29098 5.56 5.53098C5.86 5.77098 6.24002 6.25698 6.52002 6.53098L7.07001 7.085C7.10364 7.1218 7.14456 7.15119 7.19019 7.1713C7.23581 7.19141 7.28516 7.20176 7.33502 7.20176C7.38488 7.20176 7.43417 7.19141 7.4798 7.1713C7.52542 7.15119 7.56634 7.1218 7.59998 7.085L8.15997 6.52695C8.43997 6.25495 8.84 5.78097 9.13 5.55997C9.33511 5.52423 9.54622 5.55157 9.73547 5.63834C9.92473 5.72511 10.0832 5.86726 10.19 6.04599L12.85 8.98899C13.9 10.023 12.87 10.023 12.87 10.023H1.83002ZM2.28998 7.60099C2.02998 7.88699 1.65001 8.38496 1.39001 8.67496C1.10001 9.00096 0.77002 9.24896 0.77002 8.67496L0.780029 2.931C0.780029 2.544 0.770029 1.80496 1.03003 1.93796C1.18698 2.02655 1.32875 2.13966 1.45001 2.27298L3.62 4.04697C3.92942 4.26775 4.21734 4.51718 4.47998 4.79197C4.50635 4.81817 4.52729 4.84932 4.54156 4.88364C4.55584 4.91796 4.56317 4.9548 4.56317 4.99198C4.56317 5.02915 4.55584 5.06593 4.54156 5.10025C4.52729 5.13458 4.50635 5.16578 4.47998 5.19199L2.28998 7.60099ZM8.14001 5.55997C7.91362 5.80284 7.64616 6.00391 7.34998 6.15397C7.04889 6.01145 6.77747 5.81332 6.54999 5.56998L1.83002 1.74295C1.52002 1.49995 1.01001 1.00899 1.26001 0.876988C1.42268 0.814303 1.59569 0.782738 1.77002 0.78397H12.87C13.87 0.78397 13.36 1.27597 13.36 1.27597L8.14001 5.55997ZM13.28 8.67496C13.03 8.38496 12.64 7.88699 12.38 7.60099L10.19 5.19297C10.1408 5.13796 10.1136 5.06675 10.1136 4.99295C10.1136 4.91916 10.1408 4.84801 10.19 4.793C10.453 4.51885 10.7408 4.26975 11.05 4.04899L12.6801 2.71897C12.9796 2.43501 13.3005 2.17458 13.64 1.93998C13.9 1.80698 13.89 2.54596 13.89 2.93296L13.91 8.67698C13.91 9.24898 13.58 8.99996 13.28 8.67496Z" :fill="fillColor ? fillColor : '#525C69'"/>
  </svg>
</template>
<script>
export default {
  props:['fillColor']
}
</script>

